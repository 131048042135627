<template>
    <div>
        <b-row no-gutters class="min-vh-100">
            <b-col lg="2" md="3" sm="12" xs="12">
                <sider-menu-items
                    :items="menuSiderItems.items"
                />
            </b-col>
            <b-col lg="10" md="9" sm="12" xs="12">
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import SiderMenuItems from "@/components/general/elements/menus/SiderMenuItems";

export default {
    name: "ManagerContent",
    components: {SiderMenuItems},
    props: {},
    data() {
        return {
            menuSiderItems: {
                // <-- user -->
                items: [
                    {
                        type: 'text',
                        text: this.$t('manager.sider_menu.user'),
                    },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.profile'),
                        path: '/manager/profile',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.personal_data'),
                        path: '/manager/personal_data',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.organisation'),
                        path: '/manager/organisation',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.user_ordered'),
                        path: '/manager/ordered',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.purchases'),
                        path: '/manager/purchases',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.cart'),
                        path: '/manager/cart',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.e_wallet'),
                        path: '/manager/e_wallet',
                        disabled: true,
                        active: false,
                    },
                    // <-- provider_deprecated -->
                    // {
                    //     type: 'text',
                    //     text: this.$t('manager.sider_menu.provider_deprecated'), //ASKIT STOJAN
                    // },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.product'),
                        path: '/manager/products',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.provider_ordered'),
                        path: '/manager/provider_ordered',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.sold'),
                        path: '/manager/sold',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('manager.sider_menu.paper_work'),
                        path: '/manager/paper_work',
                        disabled: true,
                        active: false,
                    },
                ],
            },
        }
    },
    mounted() {

    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
